import PosPrescriptionModalComponent from "@/components/retail/pos/pointOfSale/posCart/posPrescriptionModal/PosPrescriptionModal";
import PosCustomerListComponent from "@/components/retail/pos/pointOfSale/posCustomerList/PosCustomerList.component";
import { CurrencyComponent } from "@/components/sharedComponents/currency/currency.component";
import CustomerLabelComponent from "@/components/sharedComponents/print/customerLabel/CustomerLabel.component";
import PrintReceiptComponent from "@/components/sharedComponents/print/PrintReceipt/PrintReceipt.component";
import { policyList } from "@/enums/permissions";
import { InventoryBatch } from "@/interfaces/batch";
import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import {
  BalanceItem,
  LoyalityItem,
  Order,
  OrderInvoice,
  OrderItem
} from "@/interfaces/order";
import { defaultPrescriptionDetails } from "@/interfaces/prescriptionDetails";
import {
  PaymentMethod,
  RetailSettings,
  retailSettingsDefault,
  TypePaymentMethod
} from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { Callback } from "@/types/types";
import { getLabsDataForPrintLabels } from "@/utils/batch-actions.utils";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { truncate } from "@/utils/math.utils";
import { BooleanCheck, HelixDatePickerComponent } from "helix-vue-components";
import assign from "lodash/assign";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./CheckOut.template.vue";

const namespace: string = "OrderModule";
@Component({
  mixins: [Template],
  components: {
    PosCustomerListComponent,
    CurrencyComponent,
    HelixDatePickerComponent
  }
})
export default class CheckOutComponent extends Vue {
  public discountsPanel: boolean | null = null;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public hasPermission!: BooleanCheck;
  public paymentMethods: PaymentMethod[] = [];
  public dateTime: string = "";
  public dateSale: string = "";
  public paidOrder: Order | null = null;
  public expandedItem: number[] = [];
  public bills: number[] = [];
  public payment: string = "0";
  public emailReceipt: boolean = false;
  public hasPayed: boolean = false;
  public loadingPayment: boolean = false;
  public customer: Customer | null = null;
  public fullOrder: Order | null = null;
  public lastUserInput = "0";
  public balance: BalanceItem | null = null;
  public currentPaymentMethod: PaymentMethod[] = [];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  public defaultMethod = {
    id: 3,
    type: TypePaymentMethod.CASH,
    name: retailSettingsDefault!.payment_methods![2].payment_method!.name
  };

  public currencyConfig = {
    // The character used to show the decimal place.
    decimal: ".",
    // The character used to separate numbers in groups of three.
    thousands: ",",
    // The currency name or symbol followed by a space.
    prefix: "",
    // The suffix (If a suffix is used by the target currency.)
    suffix: "",
    // Level of decimal precision. REQUIRED
    precision: 2,
    // If mask is false, outputs the number to the model. Otherwise outputs the masked string.
    masked: false
  };

  @Getter("order", { namespace })
  public order!: Order;

  @Action("newOrder", { namespace })
  public createNewOrder!: Callback;

  @Action("checkOutCustomer", { namespace: "CustomerModule" })
  public checkOutCustomer!: Callback;

  @Getter("loading", { namespace })
  protected loading!: boolean;

  protected selectedNumber: number | null = null;

  public getDiscountLabel(discount: Discount, addCountable: number) {
    return orderService.getDiscountLabel(discount, addCountable);
  }
  public getLoyaltyDiscountLabel(loyalityItem: LoyalityItem) {
    return orderService.getLoyaltyDiscountLabel(loyalityItem);
  }

  public setBills() {
    const totalAmount: number = this.getTotalOrder!;

    if (this.bills.length) {
      this.bills = [];
    }

    this.bills.push(Math.ceil(totalAmount));
    if (this.bills.indexOf(Math.ceil(totalAmount / 10) * 10) < 0) {
      this.bills.push(Math.ceil(totalAmount / 10) * 10);
    }
    if (this.bills.indexOf(Math.ceil(totalAmount / 20) * 20) < 0) {
      this.bills.push(Math.ceil(totalAmount / 20) * 20);
    }
    if (this.bills.indexOf(Math.ceil(totalAmount / 50) * 50) < 0) {
      this.bills.push(Math.ceil(totalAmount / 50) * 50);
    }
    if (this.bills.indexOf(Math.ceil(totalAmount / 100) * 100) < 0) {
      this.bills.push(Math.ceil(totalAmount / 100) * 100);
    }

    this.$forceUpdate();
  }

  @Watch("order.total", { deep: true })
  public setAllVars() {
    this.setBills();
    if (this.order && this.order.status === "PAID") {
      this.$router.push({ name: "point-of-sale" });
    }
  }

  public async created() {
    this.balance = await orderService.getBalanceCustomer(
      this.currentCustomer.customer_id!
    );
    this.checkStoreCredit();
    this.setBills();
  }
  public get additionInfoIdentification() {
    return (
      this.currentRetailSettings &&
      this.currentRetailSettings.stock_identification_type_in_pos
    );
  }

  public checkStoreCredit() {
    if (!this.balance || !this.balance.amount) {
      this.currentPaymentMethod =
        (this.currentRetailSettings &&
          (!this.balance || !this.balance.amount
            ? this.currentRetailSettings.payment_methods!.filter(
                m => m.type !== "STORE_CREDIT"
              )
            : this.currentRetailSettings.payment_methods)) ||
        [];
    } else {
      this.currentPaymentMethod =
        this.currentRetailSettings.payment_methods || [];
    }
  }

  /**
   * Due to v-currency parsing we need to save
   * the last user input in a property that avoids
   * any mutation to the original input. This is
   * in order to avoid unexpected results when using
   * multiple zeros.
   * @param keyNumber: number
   */
  public add(keyNumber: number) {
    this.payment = this.lastUserInput
      ? this.lastUserInput + keyNumber
      : this.payment.toString() + keyNumber;
    this.lastUserInput = this.payment;
  }

  /**
   * Sets input to zero (button CLEAR action).
   */
  public onClear() {
    this.payment = this.lastUserInput = "0";
  }

  /**
   * Adds programmatic ripple to pad buttons
   * when typing in the pad input field.
   * @param key: number
   */
  public onKeychange(key: number) {
    this.selectedNumber = key;

    setTimeout(() => {
      this.selectedNumber = null;
    }, 500);
  }

  public renderTraceabilityId() {
    return this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled;
  }

  public setMethod(method: PaymentMethod, amount: string | number) {
    const currentPayments = sumBy(this.paymentMethods, "amount");
    let remaining = this.getTotalOrder - currentPayments;
    if (typeof amount === "string") {
      amount = amount.slice(0, -2) + "." + amount.slice(-2);
      amount = parseFloat(amount);
    }
    this.checkStoreLimit(method, amount);
    if (
      method.type === TypePaymentMethod.STORE_CREDIT &&
      (remaining > 0 || amount < this.getTotalOrder) &&
      amount <= this.balance!.amount &&
      amount <= this.getTotalOrder
    ) {
      this.balance!.amount -= amount;
    } else if (
      method.type === TypePaymentMethod.STORE_CREDIT &&
      (remaining > 0 || amount > this.getTotalOrder) &&
      (amount > this.getTotalOrder - currentPayments ||
        amount > this.balance!.amount ||
        !method.amount)
    ) {
      if (
        (amount <= this.balance!.amount || amount > this.balance!.amount) &&
        this.balance!.amount >= this.getTotalOrder
      ) {
        amount = this.getTotalOrder;
        this.balance!.amount -= amount;
      } else {
        amount = this.balance!.amount;
        this.balance!.amount = 0;
      }

      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("refund_retail_sales.message_store_credit_exceed")),
        "red"
      );
    }
    remaining = this.getTotalOrder - +amount - currentPayments;
    remaining = remaining > 0 ? remaining : 0;
    if (method.type === TypePaymentMethod.STORE_CREDIT && amount === 0) {
      if (this.balance!.amount > this.getTotalOrder) {
        if (remaining < this.getTotalOrder && remaining > 0) {
          const data = this.paymentMethods.find(
            item => item.name === "Store Credit"
          );
          amount = this.getTotalOrder - currentPayments;
          this.balance!.amount -= amount;
        } else {
          if (remaining === 0) {
            amount = 0;
          } else {
            amount = this.getTotalOrder;
            this.balance!.amount -= this.getTotalOrder;
          }
        }
      } else {
        if (remaining > this.balance!.amount) {
          amount = this.balance!.amount;
          this.balance!.amount = 0;
        } else {
          if (remaining > 0) {
            amount = remaining;
            this.balance!.amount -= remaining;
          }
        }
      }
    }
    if (this.paymentMethods) {
      this.paymentMethods.filter(item => {
        if (item.payment_method_id === method.id) {
          if (method.name !== "Store Credit") {
            amount = item.amount! + +amount;
          }
          if (
            item.name === "Credit Card" &&
            method.type === "CREDIT_CARD" &&
            amount > this.getTotalOrder
          ) {
            amount = this.getTotalOrder;
            messagesService.showMessage(
              "fal fa-exclamation-triangle",
              String(
                this.$t("refund_retail_sales.message_store_credit_exceed")
              ),
              "red"
            );
          } else if (
            item.name === "Store Credit" &&
            method.name === "Store Credit" &&
            this.balance!.amount > 0
          ) {
            if (amount < this.getTotalOrder) {
              const data = amount;
              amount = item.amount! + +amount;
              if (amount > this.getTotalOrder) {
                const penny = this.getTotalOrder - item.amount!;
                this.balance!.amount =
                  this.balance!.amount - (item.amount! + penny) + +data;
                amount = this.getTotalOrder;
                messagesService.showMessage(
                  "fal fa-exclamation-triangle",
                  String(
                    this.$t("refund_retail_sales.message_store_credit_exceed")
                  ),
                  "red"
                );
              } else {
                if (remaining > 0 || item.amount! < this.getTotalOrder) {
                  this.balance!.amount -= item.amount!;
                }
              }
            }
            if (this.balance!.amount <= 0) {
              amount = +amount + this.balance!.amount;
              this.balance!.amount = 0;
              messagesService.showMessage(
                "fal fa-exclamation-triangle",
                String(
                  this.$t("refund_retail_sales.message_store_credit_exceed")
                ),
                "red"
              );
            } else if (
              amount > this.getTotalOrder &&
              this.balance!.amount < this.getTotalOrder
            ) {
              this.balance!.amount += +amount - item.amount!;
              amount = this.getTotalOrder;
              messagesService.showMessage(
                "fal fa-exclamation-triangle",
                String(
                  this.$t("refund_retail_sales.message_store_credit_exceed")
                ),
                "red"
              );
            }
          }
        }
      });
    }
    amount = !amount ? remaining : +amount;
    if (+amount > 0) {
      const index = this.paymentMethods.map(e => e.type).indexOf(method.type);
      if (index < 0) {
        if (
          method.name === "Credit Card" &&
          method.type === "CREDIT_CARD" &&
          amount > this.getTotalOrder
        ) {
          amount = this.getTotalOrder;
          messagesService.showMessage(
            "fal fa-exclamation-triangle",
            String(this.$t("refund_retail_sales.message_store_credit_exceed")),
            "red"
          );
        }
        this.paymentMethods.push({
          type: method.type,
          payment_method_id: method.id,
          amount,
          name: method.name
        });
      } else {
        this.paymentMethods[index].amount =
          amount || this.paymentMethods[index].amount;
      }
      this.onClear();
    }
  }
  public checkStoreLimit(method: PaymentMethod, amount: string | number) {
    if (
      this.paymentMethods.length &&
      this.paymentMethods.filter(item => item.name === "Store Credit").length &&
      method.name === "Store Credit"
    ) {
      const data = this.paymentMethods.find(
        item => item.name === "Store Credit"
      );
      if (Number(data!.amount) < this.getTotalOrder || amount) {
        this.balance!.amount += Number(data!.amount);
      }
    }
  }
  public removeMethod(method: PaymentMethod) {
    const index = this.paymentMethods.map(e => e.type).indexOf(method.type);
    if (index >= 0) {
      this.paymentMethods.splice(index, 1);
    }
    if (method.name === "Store Credit" && this.balance) {
      this.balance!.amount += Number(method.amount);
    }
  }

  public async printReceipt() {
    if (this.paidOrder) {
      if (!this.paidOrder.orderInvoice) {
        const orderInvoice: OrderInvoice = await orderService.orderInvoice(
          this.paidOrder.id!
        );
        assign(this.paidOrder, { orderInvoice });
      }
      EventBus.$emit("print", {
        component: PrintReceiptComponent,
        props: { item: this.paidOrder }
      });
    }
  }
  public get getTotalOrder() {
    return Number(
      truncate(
        this.order.total! -
          ((this.order.credit &&
            this.order.credit!.length &&
            this.order.credit![0].amount) ||
            0),
        2
      )
    );
  }

  public async printLabels() {
    if (this.paidOrder) {
      await this.setFullOrder();
      EventBus.$emit("print", {
        component: CustomerLabelComponent,
        props: {
          order: {
            ...this.fullOrder,
            order_items: await getLabsDataForPrintLabels(this.fullOrder!
              .order_items as InventoryBatch[])
          }
        }
      });
    }
  }

  public async confirmPayment() {
    const orderPayments: object[] = [];
    this.paymentMethods.forEach(method => {
      orderPayments.push({
        payment_method_id: method.payment_method_id,
        amount: (method.amount && Number(method.amount * 100) / 100) || 0,
        order_id: this.order.id,
        payment_type: method.type
      });
    });
    this.loadingPayment = true;
    if (
      this.order.customer &&
      this.order.customer.purchasingBy &&
      this.order.customer.purchasingBy.customer_id ===
        this.order.customer.customer_id
    ) {
      this.order.customer.purchasingBy = null;
    }
    const caregiverId =
      this.order.customer && this.order.customer.purchasingBy
        ? this.order.customer.purchasingBy.customer_id!
        : null;
    try {
      const response = await orderService.confirm(
        this.order,
        orderPayments,
        caregiverId,
        this.$route.params.pincode,
        this.dateTime
      );
      this.paidOrder = response;
      this.dateSale =
        this.paidOrder && this.paidOrder.sold_at
          ? fnsFormatDate(
              new Date(this.paidOrder.sold_at),
              FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
            ).toLowerCase()
          : "";
      const customerInStoreId = this.order.customer!.purchasingBy
        ? this.order.customer!.purchasingBy.customer_id
        : this.$store.state.CustomerModule.currentCustomer.customer_id;
      if (response) {
        await this.confirmCheckOut(customerInStoreId);
        this.hasPayed = true;
        if (this.currentRetailSettings.auto_print_when_sale_is_completed) {
          await this.printReceipt();
        }
      }
    } catch (e) {
      // Error message is shown in service.
    } finally {
      this.loadingPayment = false;
    }
  }
  public async confirmCheckOut(customer: number) {
    await this.checkOutCustomer(customer);
    localStorage.removeItem("order_saved");
    this.createNewOrder();
  }

  public getUsableWeight(item: OrderItem) {
    return orderService.getUsableWeight(item);
  }

  public goToPos(createOrder: boolean = false) {
    if (createOrder) {
      this.createNewOrder();
    }
    this.$router.push({ name: "point-of-sale" });
  }

  public canPayWith(method: PaymentMethod): boolean {
    return !(
      (this.order.total && this.order.total > 0) ||
      (this.order.total === 0 && method.type === "CASH")
    );
  }

  // "Amounts" view Functionality
  get remaining() {
    // @ts-ignore
    this.currentRetailSettings.payment_methods = filter(
      this.currentRetailSettings.payment_methods,
      o => o.enabled
    );
    this.currentRetailSettings.payment_methods = orderBy(
      this.currentRetailSettings.payment_methods,
      "name",
      "asc"
    );
    let sumMethods: number = 0;

    forEach(this.paymentMethods, element => {
      sumMethods = sumMethods + element.amount!;
    });

    const orderTotal = this.getTotalOrder!;
    const remaining: number = orderTotal - sumMethods;
    if (remaining > 0.009) {
      return remaining;
    }
    return 0;
  }

  get discountTotal() {
    let totalDiscounts: number = 0;
    if (this.order.discounts!.length) {
      this.order.discounts!.forEach(
        discount => (totalDiscounts += discount.amount!)
      );
    }
    return totalDiscounts;
  }

  get change() {
    let sumMethods: number = 0;
    let change: number = 0;
    this.paymentMethods.forEach(element => {
      sumMethods = sumMethods + element.amount!;
    });
    if (this.paidOrder) {
      change = Number(this.paidOrder!.change_due);
    } else {
      change = sumMethods - this.getTotalOrder!;
    }
    if (change > 0) {
      // 0.004 is added so the toFixed always rounds UP to the next decimal
      return +(change + 0.004).toFixed(2);
    }
    return 0;
  }

  get availableCoupon() {
    let coupon = 0;
    if (this.order && this.order.customer) {
      if (this.order.customer!.balance) {
        coupon = this.order.customer!.balance!.amount;
      }
    }
    return coupon;
  }

  get isCaregiver() {
    return !!(this.order.customer && this.order.customer.purchasingBy);
  }

  public async prescriptionDetails(item: OrderItem) {
    this.$modals
      .load(
        PosPrescriptionModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          model: item.prescription_details || defaultPrescriptionDetails,
          orderItem: item,
          pageType: "orderPage",
          disabled: !this.hasPermission(
            policyList.modifyPrescriptionDetailsInPos
          )
        }
      )
      .catch(() => {
        // nothing to do
      });
  }

  protected async setFullOrder() {
    this.fullOrder = await orderService.getDetailedOrder(this.paidOrder!.id!);
    if (this.fullOrder) {
      this.paidOrder!.order_items!.map(oi => {
        const index = this.fullOrder!.order_items!.findIndex(
          (item: OrderItem) => item.id === oi.id
        );
        if (index > -1) {
          this.fullOrder!.order_items![index].product_info = oi.product_info;
        }
      });
      this.fullOrder.sold_at = this.paidOrder!.sold_at;
    }
  }

  protected async mounted() {
    if (!this.order.id) {
      this.$router.push({ name: "point-of-sale" });
    } else {
      this.customer = this.order.customer!;
    }
  }
}

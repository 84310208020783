import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./currency.template.vue";

@Component({
  mixins: [Template]
})
export class CurrencyComponent extends Vue {
  // Default value (can be overriden)
  public readonly currencyConfig = {
    // The character used to show the decimal place.
    decimal: ".",
    // The character used to separate numbers in groups of three.
    thousands: ",",
    // The currency name or symbol followed by a space.
    prefix: "",
    // The suffix (If a suffix is used by the target currency.)
    suffix: "",
    // Level of decimal precision. REQUIRED
    precision: 2,
    // If mask is false, outputs the number to the model. Otherwise outputs the masked string.
    masked: true
  };

  @Prop()
  public value!: string;
  public model = "";

  @Watch("value")
  public inputW(value: string) {
    this.model = value;
  }

  @Watch("model")
  public updateModel(value: string) {
    this.$emit("input", value);
  }

  get classes() {
    return this.$el && this.$el.classList.value;
  }

  protected mounted() {
    this.model = this.value;
  }

  protected onKeypress(event: KeyboardEvent) {
    if (!isNaN(+event.key)) {
      this.$emit("keychange", +event.key);
    }
  }
}
